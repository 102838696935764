// thanks stackoverflow
// https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
function timeSince(date) {
	const seconds = Math.floor((new Date().getTime() - date) / 1000);
	let interval = Math.floor(seconds / 31536000);

	if (interval > 1) {
		return interval + "\xa0years";
	}

	interval = Math.floor(seconds / 2592000);
	
	if (interval > 1) {
		return interval + "\xa0months";
	}
	
	interval = Math.floor(seconds / 86400);
	
	if (interval > 1) {
		return interval + "\xa0days";
	}
	
	interval = Math.floor(seconds / 3600);
	
	if (interval > 1) {
		return interval + "\xa0hours";
	}
	
	interval = Math.floor(seconds / 60);
	
	if (interval > 1) {
		return interval + "\xa0minutes";
	}
	
	return Math.floor(seconds) + "\xa0seconds";
}

export default timeSince;